<template>
  <div id="moreUserInfo">
    <p class="tips">资料的真实可信直接影响商家对你的信任</p>
    <TopBar class="background">
      <template #leftInfo>
        <div style="white-space: nowrap"></div>
      </template>
      <template #middleInfo> <span class="topTitle">完善信息</span> </template>
      <template #rightInfo> </template>
    </TopBar>
    <div class="cellWrap">
      <!-- 地址选择 -->
      <van-popup v-model="AdressShow" position="bottom" :style="{ height: '80%' }" visible-item-count="6">
        <!-- <van-area value="310101" title="请选择地址" :area-list="areaList" :columns-placeholder="['请选择', '请选择', '请选择']" loading: true @cancel="cancel" @confirm="confirm" /> -->
        <SelectAdress @getEmitObj="getEmitObj" @cancel="serviceAddressCancel" />
      </van-popup>
      <!-- 服务类目 -->
      <van-popup v-model="serviceShow" position="bottom" :style="{ height: '80%' }">
        <div class="propWrap">
          <tool-bar leftTitle="取消" rightTitle="确定" title="服务类目" @confirm="reviewServiceMenu" @cancel="() => {serviceShow = false}" />
          <van-collapse v-model="activeName" accordion>
            <van-collapse-item title="主要服务类目" name="1">
              <ServiceType :copyServiceObj="copyServiceObj_main" selectNum="1" key1="1" title="主服务类目(至多一个)" @activeArrFalse="activeArrFalse1" @activeArrTrue="activeArrTrue1" />
            </van-collapse-item>
            <van-collapse-item title="附加服务类目" name="2">
              <div style="margin-bottom: 80px">
                <ServiceType :copyServiceObj="copyServiceObj_add" selectNum="16" key1="2" title="附加服务类目" @activeArrTrue2="activeArrTrue2" @activeArrFalse2="activeArrFalse2" />
              </div>
            </van-collapse-item>
            <!-- <div style="position: fixed; bottom: 0; left: 0; right: 0;z-index:99">
              <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click="reviewServiceMenu">
                确定
              </van-button>
            </div> -->
          </van-collapse>
        </div>
      </van-popup>
      <!-- 服务类型弹出层 -->
      <van-popup v-model="serviceTypeShow" position="bottom" :style="{ height: '60%' }">
        <tool-bar leftTitle="全选" rightTitle="确认" title="服务类型" @confirm="serviceTypeRight" @cancel="checkAll" />
        <div class="propWrap">
          <van-checkbox-group v-model="result" @change="changeType" ref="checkboxGroup">
            <van-cell-group>
              <van-cell v-for="(item, index) in list" clickable :key="index" :title="`${item.type_name}`" @click="toggle(index)">
                <template #right-icon>
                  <van-checkbox :name="item" ref="checkboxes" checked-color="#FF816C" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-checkbox-group>
        </div>
      </van-popup>
      <van-cell title="服务区域" icon="location-o">
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon>
          <span @click="changeAdress" class="fontWrap">{{ userAdressInfo }}</span>
        </template>
      </van-cell>
      <van-cell title="服务类目" icon="orders-o">
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon>
          <span @click="changeService" class="fontWrap">{{ serviceInfo }}</span>
        </template>
      </van-cell>
      <van-cell title="服务类型" icon="flag-o" style="white-space: nowrap">
        <template #right-icon>
          <span @click="changeServiceType" class="fontWrap">
            {{serviceTypeInfo}}
          </span>
        </template>
      </van-cell>
      <van-cell title="常驻地址" icon="hotel-o">
        <van-popup v-model="alsoAddressShow" position="bottom" :style="{ height: '60%' }" visible-item-count="2">
          <van-area value="310101" title="请选择地址" :area-list="areaList" :columns-placeholder="['请选择', '请选择', '请选择']" loading: true @cancel="alsoAddressLeft" @confirm="alsoAddressRight" />
        </van-popup>
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon> <span @click="changealsoAdress" class="fontWrap">{{alsoAdressInfo}}</span> </template>
      </van-cell>
      <van-cell title="详细地址" icon="bag-o">
        <template #right-icon>
          <input type="text" placeholder="输入详细地址" class="urgentPhone" v-model="detailAdressInfo" />
        </template>
      </van-cell>
      <van-cell title="紧急联系人电话" icon="phone-o">
        <!-- 使用 right-icon 插槽来自定义右侧图标 -->
        <template #right-icon>
          <input type="text" placeholder="输入手机号" class="urgentPhone" v-model="urgentPhone" maxlength="11" />
        </template>
      </van-cell>
    </div>
    <div class="bottomButton" @click="submitMoreInfo">点击提交</div>
  </div>
</template>
<script>
import ServiceType from "@/components/Mine/baseInfo/ServiceType.vue";
import SelectAdress from "@/components/effect/SelectAdress.vue";
import TopBar from "@/components/home/TopBar.vue";
import ToolBar from "@/components/effect/ToolBar.vue";

import add from "@/assets/area.js";
import { Toast } from "vant";
export default {
  data() {
    return {
      activeIds: [1, 2, 3],
      activeIndex: 0,

      serviceInfo: "选择服务类目 >",
      serviceInfoArr: [],
      serviceShow: false,
      service_categories: [],
      serviceObj: [],

      copyServiceObj_main: [],
      copyServiceObj_add: [],
      activeArrA: [],
      activeArrB: [],
      activeName: 1,

      AdressShow: false,
      userAdress: [],
      userAdressInfo: "选择服务地区 >",
      false1: false,

      serviceTypeShow: false,
      serviceTypeInfo: "选择服务类型 >",
      list: [],
      listShow: false,
      result: [],
      newResult: [],
      alsoAddressShow: false,
      alsoAdress: {},
      alsoAdressInfo: "选择常驻地址 >",
      detailAdressInfo: "",
      urgentPhone: "",

      areaList: add,
      dotTemp: "",
      maxSelect: 3,
      num: 0,
      flag: true,
    };
  },
  components: {
    TopBar,
    SelectAdress,
    ServiceType,
    ToolBar,
  },

  created() {
    // 服务类目接口获取
    this.$ajax
      .get("/api/common/firstCategory", {})
      .then((res) => {
        res.data.forEach((item) => {
          item["datHave"] = false;
        });
        this.serviceObj = res.data;
        this.copyServiceObj_main = JSON.parse(JSON.stringify(this.serviceObj));
        this.copyServiceObj_add = JSON.parse(JSON.stringify(this.serviceObj));
      });

    // 服务类型接口获取
    this.$ajax
      .get(`/api/common/serviceMode?id=1`, {})
      .then((res) => {
        this.list = res.data;
      });
  },
  mounted() {},
  methods: {
    activeArrTrue1(e) {
      this.activeArrA = e;
      console.log("主分类选中的列表", this.activeArrA);
      this.copyServiceObj_add.forEach((item, index) => {
        this.activeArrA.forEach((el, i) => {
          if (item.id == el.id) {
            this.copyServiceObj_add.remove(item);
          }
        });
      });
      this.activeArrA.forEach((item, index) => {
        this.activeArrB.forEach((el, i) => {
          if (item.id == el.id) {
            this.activeArrB.remove(el);
          }
        });
      });
    },
    activeArrFalse1(e) {
      this.copyServiceObj_add.push(e);
    },
    activeArrTrue2(e) {
      this.activeArrB = e;
      console.log("次分类选中的列表", this.activeArrB);
    },
    activeArrFalse2(e) {
      console.log("次分类选中的列表", this.activeArrB);
    },
    reviewServiceMenu() {
      if (this.activeArrA.length != 0 && this.activeArrB.length != 0) {
        let _allArr = this.activeArrA.concat(this.activeArrB);
        let _allArrName = [];
        let _allArrId = [];
        console.log("合起来的类目", _allArr);
        _allArr.forEach((item, index) => {
          _allArrName.push(item.type_name);
          _allArrId.push(item.id);
        });
        this.service_categories = _allArrId;
        this.serviceInfo = _allArrName.join(",");
        this.serviceShow = false;
      } else {
        this.$toast("请完善分类信息");
      }
    },
    getEmitObj(res) {
      console.log(res);
      this.AdressShow = res.close;
      this.userAdressInfo = "";
      let _temp = [];
      res.result.forEach((item) => {
        _temp.push(item.name);
        this.userAdress.push(item.code);
      });
      this.userAdressInfo = `${res.province.name},${res.city.name},${_temp.join(
        ","
      )}`;
      // this.userAdressInfo = `${res.provinces},${res.city}`
      // console.log(res);
    },
    changeService() {
      this.serviceShow = true;
    },
    changeAdress() {
      this.AdressShow = true;
    },
    changeServiceType() {
      this.serviceTypeShow = true;
    },
    changealsoAdress() {
      this.alsoAddressShow = true;
    },
    serviceAddressCancel(e) {
      console.log(e);
      this.AdressShow = false;
    },
    cancel() {
      this.AdressShow = false;
    },
    // confirm(res) {
    //   // console.log(res);
    //   this.userAdress = res;
    //   this.userAdressInfo = `${this.userAdress[0].name}  ${this.userAdress[1].name}  ${this.userAdress[2].name} `;
    //   this.AdressShow = false;
    // },

    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    checkAll() {
      this.$refs.checkboxGroup.toggleAll(true);
    },
    serviceTypeRight() {
      let resultList = [];

      this.result.forEach((item, index) => {
        resultList.push(item.type_name);
        this.newResult.push(item.id);
      });
      this.serviceTypeInfo = resultList.join(",");
      this.serviceTypeShow = false;
    },
    changeType() {
      // console.log(this.result, "选中的服务类型");
      // this.serviceTypeInfo = this.result.join(",");
      // console.log(this.serviceTypeInfo);
    },
    alsoAddressLeft() {
      this.alsoAddressShow = false;
    },
    alsoAddressRight(res) {
      // console.log(res, "also");
      this.alsoAdress = res;
      this.alsoAdressInfo = `${res[0].name}  ${res[1].name}  ${res[2].name} `;
      this.alsoAddressShow = false;
    },
    submitMoreInfo() {
      // console.log(this.userAdress[0].code);
      // console.log(this.service_categories.join());
      // console.log(this.newResult.join());
      if (this.userAdress.length == 0) {
        Toast("请填写完整服务区域");
      } else if (this.service_categories.length == 0) {
        Toast("请填写完整服务类目");
      } else if (this.serviceTypeInfo.length == 0) {
        Toast("请填写完整服务类型");
      } else if (this.alsoAdress.length != 3) {
        Toast("请填写完整常驻地址");
      } else if (this.detailAdressInfo.length == 0) {
        Toast("请填写完整详细地址");
      } else if (!/^1[3456789]\d{9}$/.test(this.urgentPhone)) {
        Toast("请输入正确的手机号码");
      } else {
        this.$http
          .post("api/v2/worker/profile", {
            service_areas: this.userAdress,
            service_categories: this.service_categories.join(","),
            service_modes: this.newResult.join(","),
            address_province: this.alsoAdress[0].code,
            address_city: this.alsoAdress[1].code,
            address_county: this.alsoAdress[2].code,
            address_detail: this.detailAdressInfo,
            urgent_contact_mobile: this.urgentPhone,
          })
          .then((res) => {
            console.log(res);
            if (res.data.code === 0) {
              Toast("提交成功");
              this.goSubPage("home");
            }
          });
      }
    },
  },
};
</script>
<style lang="less">
#moreUserInfo {
  .tips {
    margin-top: 45px;
    padding: 1.5rem;
    text-align: center;
    font-size: 1.4rem;
  }
  .cellWrap {
    // margin-top: 45px;
    box-sizing: border-box;
    width: 95%;
    margin: 0 auto;
    padding: 2rem 0rem;
    background: #fff;
    border-radius: 10px;
    .urgentPhone {
      width: 50%;
      border: none;
    }
  }
  .serviceType {
    width: 100%;
    // margin-top: 3rem;
    .selectedType {
      font-size: 1.7rem;
      margin: 2rem 0;
      span {
        color: red;
      }
    }
  }
  .avan-grid-item__text {
    color: red;
    font-weight: bold;
  }
  .van-grid {
    padding-top: 2rem;
  }
  .serviceButton {
    width: 70%;
    margin: 0 auto;
  }
  .propWrap {
    // margin: 1rem;
    box-sizing: border-box;
  }
  .fontWrap {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    font-size: 1.4rem;
    width: 40%;
    text-align: right;
  }
  .van-picker__title,
  .van-picker__confirm {
    color: #000;
  }
  .van-cell__title {
    white-space: nowrap;
  }
  .bottomButton {
    width: 90%;
    // margin-bottom: 160px;
    // margin-bottom: 60px;
    margin: 0 auto;
    border-radius: 10rem;
    background: linear-gradient(to right, #ff6034, #ee0a24);
    height: 50px;
    font-size: 1.7rem;
    // font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 50px;
    margin-top: 3rem;
  }
}
</style>